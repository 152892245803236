import classes from './Card.module.scss'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
// actions/thunks
import fullProfileThunks from '../../../../../features/thunks/fullProfileThunks'
import { cardsActions } from '../../../../../features/slices/cardsSlice'
// other
import getAgeWord from '../../../../../other/utils'
// svgs
import toFavSvg from '../../../../../svgs/heartPlus14.svg'
import fromFavSvg from '../../../../../svgs/heartMinus14.svg'

const Card = ({ children, equals, ...rest }) => {
  const dispatch = useDispatch()
  const card = children
  const filter = useSelector(state => state.filter)
  const isMbrOvrIncluded = card.preferences.filter(pref => pref === 'Минет без презерватива' || pref === 'Окончание в рот').length > 1
  const [isFavorite, setIsFavorite] = useState(false)

  useEffect(() => {
    const favorites = JSON.parse(localStorage.getItem('favorites')) || []
    setIsFavorite(favorites.includes(card.phone))
  }, [card.phone])

  function getPriceColorClass(price) {
    if (!filter.isPricesHighlighted) return null

    return price < 3000 ? 'lPrice'
      : price >= 3000 && price <= 5000 ? 'mPrice'
      : price > 5000 ? 'hPrice'
      : ''
  }
  function limitStr(str, length) {
    return str.trim().slice(0, length) + (length < str.length ? '..' : '')
  }
  function changeFavInStorage (phone) {
    const favorites = JSON.parse(localStorage.getItem('favorites')) || []

    if (favorites.includes(phone)) {
      // удаление из избранного
      const updatedFavorites = favorites.filter(fav => fav !== phone)
      localStorage.setItem('favorites', JSON.stringify(updatedFavorites))
      setIsFavorite(false)
    } else {
      // добавление в избранное
      favorites.push(phone)
      localStorage.setItem('favorites', JSON.stringify(favorites))
      setIsFavorite(true)
    }
  }

  function handleClick() {
    dispatch(fullProfileThunks.fetch(card.originalIdx))
  }
  function handleFavBtnClick(e, phone) {
    e.stopPropagation()
    changeFavInStorage(phone)
    dispatch(cardsActions.sort(filter.sortType))
  }

  return (
    <motion.div className={classes.wrapper} onClick={handleClick} {...rest}>
      <div className={classes.photoContainer}>
        <img
          className={`${classes.photo} ${filter.isPhotosBlured && 'blur'}`}
          src={card.photo}
          alt=''
        />
      </div>

      <div className={classes.info}>
        <h2 className={classes.name}>{limitStr(card.name, 12)}</h2>
        <p className={classes.phone}>
          {card.phone + ' '}
          <span className={classes.equals}>
            {equals?.map(dig => `#${dig}`).join(' ')}
          </span>
          <span className={classes.favBtn} onClick={(e) => handleFavBtnClick(e, card.phone)}>
            {isFavorite ? <img src={fromFavSvg} alt='FromFav' /> : <img src={toFavSvg} alt='ToFav' />}
          </span>
        </p>

        <p className={classes.anthropometry}>
          {getAgeWord(+card.age)}
          <span className={classes.splitter} />
          {card.height} см
          <span className={classes.splitter} />
          {card.weight} кг
        </p>

        <div className={classes.pricesBox}>
          {card.prices.aparts && <p className={`${classes.price} ${classes[getPriceColorClass(card.prices.aparts)]}`}>час {card.prices.aparts}₽</p>}
          {card.prices.outcall && <p className={`${classes.price} ${classes[getPriceColorClass(card.prices.outcall)]}`}>взв {card.prices.outcall}₽</p>}
          {card.isExpressIncluded && <p className={`${classes.price} ${classes.express}`}>экспресс</p>}
          {isMbrOvrIncluded && <p className={`${classes.price}`}>МБР + ОВР</p>}
          {card.isVideoIncluded && <p className={`${classes.price}`}>видео</p>}
        </div>

        <p className={classes.description}>{limitStr(card.description, 200)}</p>
      </div>

      <div className={classes.index}>#{card.index + 1}</div>
    </motion.div>
  )
}

export default Card
