import { createAsyncThunk } from '@reduxjs/toolkit'
import fetchHtml from '../../other/fetchHtml'
import env from '../../other/environment'

const fullProfileThunks = {
  fetch: createAsyncThunk('fullProfile/fetch', async (originalIdx, { rejectWithValue }) => {
    try {
      return await fetchHtml(`${env.urls.ORIGINAL}${env.urls.PROXY}${env.urls.SOURCE}girls/${originalIdx}`)
    } catch (error) {
      console.error(error.response?.data)
      return rejectWithValue(error.response?.data)
    }
  })
}

export default Object.freeze(fullProfileThunks)
