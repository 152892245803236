import classes from './CardsList.module.scss'
// other
import animations from '../../../../other/animations'
// nested
import Card from './Card/Card'

const CardsList = ({ children }) => {
  function findEquals(cards, targetCard) {
    return cards.filter(card => card.phone === targetCard.phone && card.index !== targetCard.index).map(card => card.index + 1)
  }

  return <>
    <div className={classes.wrapper}>
      {children.map((card, idx) => <Card
          key={idx}
          equals={findEquals(children, card)}

          initial={animations.profiles.initial}
          animate={animations.profiles.animate(idx)}
      >{card}</Card>)}
    </div>
  </>
}

export default CardsList
