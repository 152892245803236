import classes from './Header.module.scss'
import { motion } from 'framer-motion'
import animations from '../../../../other/animations'
import env from '../../../../other/environment'

const Header = () => {
  return <>
    <div className={classes.wrapper}>
      <motion.h1
        className={classes.title}
        onClick={ () => window.open(env.urls.SOURCE, '_blank') }
        {...animations.title}
      >dsgomsk</motion.h1>
    </div>
  </>
}

export default Header
