const env = {
  urls: {
    // ORIGINAL: 'https://localhost:3000/',
    ORIGINAL: 'https://dsgomsk.space/',
    PROXY: 'api?url=',
    SOURCE: 'https://berry.dosug-55omsk.net/',
    NEW: 'new' // ждут звонка
  },
}

export default Object.freeze(env)
