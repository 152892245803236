import classes from './FullProfile.module.scss'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// other
import animations from '../../../../other/animations'
// actions/thunks
import { fullProfileActions } from '../../../../features/slices/fullProfileSlice'
// nested
import Slider from './Slider/Slider'
import Info from './Info/Info'
import Comments from './Comments/Comments'
// ui
import Button from '../../../ui/Button/Button'
import Box from '../../../ui/Box/Box'

const FullProfile = ({ className, ...rest }) => {
  const dispatch = useDispatch()
  const profile = useSelector(state => state.fullProfile)
  const [isVideoShowUp, setIsVideoShowUp] = useState(false)
  const isCommentsExist = profile.comments.length > 0
  const isVideoIncluded = !profile.videoSrc?.endsWith('undefined')
  
  return <>
    {profile.isLoaded
    ? <Box
      className={`${classes.wrapper} ${className}`}
      isCloseBtnExists={true}
      onClick={e => e.stopPropagation()}
      onClose={() => dispatch(fullProfileActions.reset())}
      {...animations.profile}
      {...rest}
    >
      <h1 className={classes.name}>{profile.name}</h1>
      <h3 className={classes.phone}>{profile.phone}</h3>
      <Slider className={classes.slider}>{profile.photosSrc}</Slider>
      {isVideoIncluded && <Button className={classes.isVideoShowUpBtn} onClick={() => setIsVideoShowUp(!isVideoShowUp)}>{!isVideoShowUp ? 'показать видео' : 'скрыть видео'}</Button>}
      {isVideoShowUp && <video className={classes.video} src={profile.videoSrc} controls loop muted autoPlay></video>}
      <p className={classes.description}>{profile.description.length > 0 ? profile.description : 'Описание отсутствует. 😞'}</p>
      <Info profile={profile} />
      {isCommentsExist && <Comments profileName={profile.name}>{profile.comments}</Comments>}
    </Box>
    : null}
  </>
}

export default FullProfile
