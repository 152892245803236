import { configureStore } from '@reduxjs/toolkit'
import filterReducer from './slices/filterSlice'
import cardsReducer from './slices/cardsSlice'
import fullProfileReducer from './slices/fullProfileSlice'

const store = configureStore({
  reducer: {
    filter: filterReducer,
    cards: cardsReducer,
    fullProfile: fullProfileReducer
  }
})

export default store
