import { Provider } from 'react-redux'
import store from './features/store'
import MainPage from './components/pages/MainPage/MainPage'

const App = () => {
  return <>
    <Provider store={store}>
      <MainPage />
    </Provider>
  </>
}

export default App
