import env from './environment'

export default function parseCardsPage(html) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const profiles = []
  const profileElements = doc.querySelectorAll('.grids.nthchild')

  profileElements.forEach((profileElement, index) => {
    const nameAgeElement = profileElement.querySelector('a[href^="/girls/"]')
    const [name, age] = nameAgeElement?.textContent.trim().split(' - ') ?? [null, null]
    const phoneElement = profileElement.querySelector('.grid-6 a[href^="/girls/"]')
    const heightWeightElement = Array.from(profileElement.querySelectorAll('p')).find(p => p.textContent.includes('Рост:'))
    const descriptionElement = profileElement.querySelector('.grid-6 p')
    const imgElement = profileElement.querySelector('img')
    const priceTableElement = profileElement.querySelector('.main-table')
    const preferencesElement = profileElement.querySelector('.grid-5.hidden-phone')
    const hasExpressElement = profileElement.querySelector('.existInExClass')
    const hasVideoElement = profileElement.querySelector('.is_videoPage')
    const linkElement = profileElement.querySelector('a[href^="/girls/"]')

    const profile = {
        index,
        name,
        age: parseInt(age?.replace(' лет', ''), 10),
        height: parseInt(heightWeightElement?.textContent.match(/Рост: (\d+)/)[1], 10),
        weight: parseInt(heightWeightElement?.textContent.match(/Вес: (\d+)/)[1], 10),
        photo: env.urls.SOURCE + imgElement?.src.replace(/^.*?(uploads)/, '$1'),
        phone: phoneElement?.textContent.trim(),
        description: descriptionElement?.textContent.trim(),
        prices: [],
        preferences: [],
        isExpressIncluded: hasExpressElement?.style.display !== 'none' ?? false,
        isVideoIncluded: Boolean(hasVideoElement),
        isFavorite: null,
        originalIdx: linkElement?.href.match(/(\d+)$/)[0]
    }

    if (priceTableElement) {
        const rows = priceTableElement.querySelectorAll('tr')
        rows.forEach(row => {
            const cells = row.querySelectorAll('td')
            if (cells.length === 3) {
                profile.prices.push({
                    apartments: cells[1].textContent.trim(),
                    outcall: cells[2].textContent.trim()
                })
            }
        })
    }
    
    profile.prices = {
        aparts: profile.prices[0]?.apartments !== '-' ? profile.prices[0]?.apartments : null,
        outcall: profile.prices[0]?.outcall !== '-' ? profile.prices[0]?.outcall : null
    }

    if (preferencesElement) {
        const preferenceLists = preferencesElement.querySelectorAll('ul')
        preferenceLists.forEach(list => {
            const items = list.querySelectorAll('li')
            items.forEach(item => {
                profile.preferences.push(item.textContent.trim())
            })
        })
    }

    profiles.push(profile)
  })

  return profiles.slice(0, -1)
}
