import { createAsyncThunk } from '@reduxjs/toolkit'
import fetchHtml from '../../other/fetchHtml'
import env from '../../other/environment'

const cardsThunks = {
  fetch: createAsyncThunk('cards/fetch', async (_, { rejectWithValue }) => {
    try {
      return await fetchHtml(`${env.urls.ORIGINAL}${env.urls.PROXY}${env.urls.SOURCE}${env.urls.NEW}`)
    } catch (error) {
      console.error(error.response?.data)
      return rejectWithValue(error.response?.data)
    }
  })
}

export default Object.freeze(cardsThunks)
