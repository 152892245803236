import { createSlice } from '@reduxjs/toolkit'
import fullProfileThunks from '../thunks/fullProfileThunks'
import parseFullProfilePage from '../../other/parseFullProfilePage'

const initialState = {
  name: null,
  age: null,
  phone: null,
  description: null,
  height: null,
  weight: null,
  district: null,
  hairColor: null,
  breastSize: null,
  photosSrc: [],
  videoSrc: null,
  prices: { hour: null, outcallHour: null },
  express: false,
  preferences: [],
  comments: [],
  isLoaded: false,
  status: 'idle'
}

const fullProfileSlice = createSlice({
  name: 'fullProfile',
  initialState,
  reducers: {
    set: (state, { payload }) => ({ ...state, ...payload }),
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fullProfileThunks.fetch.pending, (state) => {
        state.status = 'loading'
        state.isLoaded = false
      })
      .addCase(fullProfileThunks.fetch.fulfilled, (state, { payload }) => {
        Object.assign(state, parseFullProfilePage(payload), { status: 'succeeded', isLoaded: true })
      })
      .addCase(fullProfileThunks.fetch.rejected, (state) => {
        state.status = 'failed'
        state.isLoaded = false
      })
  }
})

export const fullProfileActions = fullProfileSlice.actions
export default fullProfileSlice.reducer
