import classes from './Filter.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { AnimatePresence } from 'framer-motion'
// actions
import { filterActions } from '../../../../features/slices/filterSlice'
import { cardsActions } from '../../../../features/slices/cardsSlice'
// other
import animations from '../../../../other/animations'
// ui
import Box from '../../../ui/Box/Box'
import Switcher from '../../../ui/Switcher/Switcher'
import { useEffect } from 'react'

const Filter = ({ className, ...rest }) => {
  const filter = useSelector(state => state.filter)
  const dispatch = useDispatch()

  useEffect(() => {
    loadFromLocalStorage()
  }, [])
  
  useEffect(() => {
    saveToLocalStorage()
  }, [filter])

  function handleSortTypeChange(type) {
    dispatch(filterActions.changeSortType(type))
    dispatch(cardsActions.sort(type))
  }
  function saveToLocalStorage() {
    const  { sortType, isPricesHighlighted, isPhotosBlured } = filter
    localStorage.setItem('filter', JSON.stringify({ sortType, isPricesHighlighted, isPhotosBlured }))
  }
  function loadFromLocalStorage() {
    const saved = JSON.parse(localStorage.getItem('filter'))
    if (saved) dispatch(filterActions.loadParams(saved))
  }

  return <>
    <AnimatePresence>
      {filter.isVisible && <Box
        className={`${classes.wrapper} ${className}`}
        title='Фильтры'
        titleSize={18}
        isCloseBtnExists={true}
        onClick={(e) => e.stopPropagation()}
        onClose={() => dispatch(filterActions.toggleVisibility())}
        {...animations.filter}
        {...rest}
      >
        <select
          className={classes.sortSelect}
          value={filter.sortType}
          onChange={(e) => handleSortTypeChange(e.target.value)}
        >
          <option value='byIndex' defaultValue={true}>по порядку</option>
          <option value='byPrice'>по цене</option>
          <option disabled>-</option>
          <option value='byAgeAsc'>по возрасту △</option>
          <option value='byAgeDesc'>по возрасту ▽</option>
          <option disabled>-</option>
          <option value='byHeightAsc'>по росту △</option>
          <option value='byHeightDesc'>по росту ▽</option>
          <option disabled>-</option>
          <option value='byWeightAsc'>по весу △</option>
          <option value='byWeightDesc'>по весу ▽</option>
        </select>

        <Switcher
          className={classes.isPricesHighlighted}
          isChecked={filter.isPricesHighlighted}
          onSwitch={() => dispatch(filterActions.togglePricesHighlight())}
        >выделять цены</Switcher>

        <Switcher
          className={classes.isPhotosBlured}
          isChecked={filter.isPhotosBlured}
          onSwitch={() => dispatch(filterActions.togglePhotosBlur())}
        >размывать фото</Switcher>

        {/* <Switcher
          className={classes.isOnlyVideo}
          isChecked={filter.isOnlyVideo}
          onSwitch={() => dispatch(filterActions.toggleOnlyVideoSwitcher())}
        >только с видео</Switcher>

        <Switcher
          className={classes.isOnlyMbrOvr}
          isChecked={filter.isOnlyMbrOvr}
          onSwitch={() => dispatch(filterActions.toggleOnlyMbrOvrSwitcher())}
        >только МБР + ОВР</Switcher> */}
      </Box>}
    </AnimatePresence>
  </>
}

export default Filter
