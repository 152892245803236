import { createSlice } from '@reduxjs/toolkit'
import cardsThunks from '../thunks/cardsThunks'
import parseCardsPage from '../../other/parseCardsPage'
import env from '../../other/environment'

const cardsSlice = createSlice({
  name: 'cards',
  initialState: {
    items: [],
    isLoaded: false,
    status: 'idle'
  },
  reducers: {
    set: (state, { payload }) => ({ ...state, items: payload }),
    sort: (state, { payload }) => {
      let sorted

      switch (payload) {
        case 'byIndex': sorted = sortByIndex(state.items); break
        case 'byPrice': sorted = sortByPrice(state.items, 'asc'); break
        case 'byAgeAsc': sorted = sortByAge(state.items, 'asc'); break
        case 'byAgeDesc': sorted = sortByAge(state.items, 'desc'); break
        case 'byHeightAsc': sorted = sortByHeight(state.items, 'asc'); break
        case 'byHeightDesc': sorted = sortByHeight(state.items, 'desc'); break
        case 'byWeightAsc': sorted = sortByWeight(state.items, 'asc'); break
        case 'byWeightDesc': sorted = sortByWeight(state.items, 'desc'); break
        default: sorted = [...state.items]
      }

      return { ...state, items: sortByFavs(sorted) }

      function sortByIndex(cards) {
        return [...cards].sort((a, b) => a.index - b.index)
      }
      function sortByPrice(cards) {
        return [...cards].sort((a, b) => {
          const aAparts = a.prices.aparts === null ? Infinity : +a.prices.aparts
          const bAparts = b.prices.aparts === null ? Infinity : +b.prices.aparts
          return (aAparts - bAparts) || (+a.prices.outcall - +b.prices.outcall)
        })
      }
      function sortByAge(cards, order = 'asc') {
          return [...cards].sort((a, b) => {
              const ageComparison = a.age - b.age
              return order === 'desc' ? -ageComparison : ageComparison
          })
      }
      function sortByHeight(cards, order = 'asc') {
          return [...cards].sort((a, b) => {
              const heightComparison = a.height - b.height
              return order === 'desc' ? -heightComparison : heightComparison
          })
      }
      function sortByWeight(cards, order = 'asc') {
          return [...cards].sort((a, b) => {
              const weightComparison = a.weight - b.weight
              return order === 'desc' ? -weightComparison : weightComparison
          })
      }
      function sortByFavs(cards) {
        const favs = JSON.parse(localStorage.getItem('favorites')) || []
        const favoriteCards = cards.filter(card => favs.includes(card.phone))
        const otherCards = cards.filter(card => !favs.includes(card.phone))
        return [...favoriteCards, ...otherCards]
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(cardsThunks.fetch.pending, (state) => {
        state.status = 'loading'
        state.isLoaded = false
      })
      .addCase(cardsThunks.fetch.fulfilled, (state, { payload }) => {
        state.status = 'succeeded'
        state.isLoaded = true
        state.items = parseCardsPage(payload, env.urls.SOURCE)
      })
      .addCase(cardsThunks.fetch.rejected, (state) => {
        state.status = 'failed'
        state.isLoaded = false
      })
  }
})

export const cardsActions = cardsSlice.actions
export default cardsSlice.reducer
