import classes from './Sidebar.module.scss'
import { useDispatch } from 'react-redux'
import { AnimatePresence } from 'framer-motion'
// actions
import { filterActions } from '../../../../features/slices/filterSlice'
import cardsThunks from '../../../../features/thunks/cardsThunks'
// other
import animations from '../../../../other/animations'
// ui
import CircleBtn from '../../../ui/CircleBtn/CircleBtn'
// svgs
import filterSvgSrc from '../../../../svgs/filter24.svg'
import refreshSvgSrc from '../../../../svgs/refresh24.svg'
import arrowUpSvgSrc from '../../../../svgs/arrowUp24.svg'

const Sidebar = ({ scrollPosition, className, ...rest }) => {
  const dispatch = useDispatch()

  return <>
    <div className={classes.wrapper} {...rest}>
      <div className={classes.top}>
        <CircleBtn onClick={() => dispatch(filterActions.toggleVisibility())}>
          <img src={filterSvgSrc} alt='F' />
        </CircleBtn>

        <CircleBtn onClick={() => dispatch(cardsThunks.fetch())}>
          <img src={refreshSvgSrc} alt='R' />
        </CircleBtn>
      </div>

      <div className={classes.bottom}>
        <AnimatePresence>
          {scrollPosition > 500 && <CircleBtn
            className={classes.arrowUp}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            {...animations.overlay}
          >
            <img src={arrowUpSvgSrc} alt='Up' />
          </CircleBtn>}
        </AnimatePresence>
      </div>
    </div>
  </>
}

export default Sidebar
