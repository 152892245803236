import { createSlice } from '@reduxjs/toolkit'

const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    isVisible: false,
    isPricesHighlighted: false,
    isPhotosBlured: true,
    isOnlyVideo: false,
    isOnlyMbrOvr: false,
    sortType: 'byIndex',
    favBtnClickTrigger: 0 // для отслеживания нажатия кнопки "в/из избранное" в useEffect
  },
  reducers: {
    toggleVisibility: state => ({ ...state, isVisible: !state.isVisible }),
    togglePricesHighlight: state => ({ ...state, isPricesHighlighted: !state.isPricesHighlighted }),
    togglePhotosBlur: state => ({ ...state, isPhotosBlured: !state.isPhotosBlured }),
    toggleOnlyVideoSwitcher: state => ({ ...state, isOnlyVideo: !state.isOnlyVideo }),
    toggleOnlyMbrOvrSwitcher: state => ({ ...state, isOnlyMbrOvr: !state.isOnlyMbrOvr }),
    changeSortType: (state, { payload }) => ({ ...state, sortType: payload }),
    loadParams: (state, { payload }) => ({ ...state, ...payload })
  }
})

export const filterActions = filterSlice.actions
export default filterSlice.reducer
